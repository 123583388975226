import { ReactComponent as NextButton } from 'Assets/Shared/icon-next-button.svg'
import { ReactComponent as PrevButton } from 'Assets/Shared/icon-back-button.svg'
import {
  ArrowButton,
  Controls,
  Footer,
  SlideInfo,
  Wrapper,
} from './SlideFooter.styles'

const SlideFooter = ({data}) => {


  return (
    <Wrapper>
     {/* <ProgressBar width={progressBarWidth} /> */}
      <Footer>
        <SlideInfo aria-live="polite">
          <h3>{data.name}</h3>
          <p>{data.artist.name}</p>
        </SlideInfo>
        <Controls>
          <ArrowButton
            aria-label="Previous Slide"
            onClick={() => {

            }}
          >
            <PrevButton />
          </ArrowButton>
          <ArrowButton
            aria-label="Next Slide"
            onClick={() => {

            }}
          >
            <NextButton />
          </ArrowButton>
        </Controls>
      </Footer>
    </Wrapper>
  )
}

export default SlideFooter
