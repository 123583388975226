import { GlobalStyles } from 'Styles/GlobalStyles'
import Gallery from './Pages/Gallery';
import Slide from './Pages/SlideShow';
import Header from 'Components/Organisms/Header'

import { AnimatePresence } from 'framer-motion'

import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";


import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";


let clientAddress = "/graphql";
if (process.env.NODE_ENV === "development") {
  clientAddress = "http://localhost:8080/graphql";
}

const authLink = setContext((_, { headers }) => {

  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const uploadLink = createUploadLink({
  uri: clientAddress, // Apollo Server is served from port 4000
  headers: {
    "keep-alive": "true",
  },
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});


const RouterComponent= () =>{
  return <Switch>
    <Redirect exact from="/" to="/gallery/common"/>
    <Route path="/gallery/:gallery" component={Gallery}/>
    <Route path="/image/:image" component={Slide}/>
<Gallery/>
  </Switch> 
}


function App() {
  return (
    <ApolloProvider client={client}>
    <Router>
      <GlobalStyles/>
      <Header/>
      <AnimatePresence exitBeforeEnter>
        <RouterComponent/>
   
    </AnimatePresence>
    </Router>
    </ApolloProvider>
  );
}

export default App;
