import queries from "Styles/BreakPoints";
import { AnimatePresence } from "framer-motion";
import { ReactComponent as IconView } from "Assets/Shared/icon-view-image.svg";
import {
  Article,
  ArtistImageDesktop,
  ArtistImage,
  Button,
  Body,
  Caption,
  Description,
  Figure,
  Header,
  Link,
} from "./Slide.styles";
import { slideAnimation } from "Utils/Animations";
import { useState } from "react";
import { buyArt } from "GraphQLQuery";

import {
  useMutation,
} from "@apollo/react-hooks";

const ethers = require("ethers");

const Slide = ({ data }) => {

  const [processing, setProcessing] = useState(false);
  const [_buyArt] = useMutation(buyArt);


  const direction = "";
  const currentIndex = 0;



  const buyToken = async () => {

    if (processing){
      return;
    } else {
      setProcessing(true);
    }

    if (data.purchaseNumber){
      return;
    }
    if (!window?.ethereum){
      alert("No Ethereum Wallet Present");
    }
    let result = await window.ethereum.enable();
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // The MetaMask plugin also allows signing transactions to
    // send ether and pay to change state within the blockchain.
    // For this, you need the account signer...
    const signer = provider.getSigner();

    
    const tx = await signer.sendTransaction({
      to: "0xCB6BFe8B2BCa1109524bCD187785820028019F99",
      value: ethers.utils.parseEther("0.03"),
    });

    let finished = await tx.wait()

    if (finished.confirmations>0){
      await _buyArt({
        variables: {
          uuid:data._id.split(":")[1]
        }
      })

      setProcessing(false);
    }



    
  };

  return (
    <AnimatePresence initial={false} custom={direction}>
      <Article
        key={currentIndex}
        custom={direction}
        variants={slideAnimation}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
      >
        <Header>
          <Figure
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <picture>
              <source
                media={queries.tabletUp}
                srcSet={data.images.hero.large}
              />
              <img src={data.images.hero.small} alt="" />
            </picture>
            <Caption>
              <h1>{data.name}</h1>
              <p>{data.artist.name}</p>
              <ArtistImage>
                <img src={data.artist.image} alt="" />
              </ArtistImage>
            </Caption>
            <ArtistImageDesktop>
              <img src={data.artist.image} alt="" />
            </ArtistImageDesktop>
            <Button onClick={() => {}}>
              <IconView />
              <span>View image</span>
            </Button>
          </Figure>
        </Header>
        <Body year={data.year}>
          <Description>{data.description}</Description>
          <Link href={data.source} target="_blank" rel="noopener noreferrer" onClick = {buyToken}>
            {processing?"Processing":data.purchaseNumber
              ? "This NFT can be found under the ID " + data.purchaseNumber
              : "Purchase this NFT"}
          </Link>
        </Body>
      </Article>
    </AnimatePresence>
  );
};

export default Slide;
