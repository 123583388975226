import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

import queries from 'Styles/BreakPoints'
import Slide from 'Components/Organisms/Slide'
import SlideFooter from 'Components/Molecules/SlideFooter'
import { pageAnimation } from 'Utils/Animations'

import { useQuery } from "@apollo/react-hooks";
import { getArt } from "GraphQLQuery";

import { useParams } from "react-router-dom";

const Wrapper = styled(motion.main)`
  padding-top: 1.5rem;
  display: flex;
  padding: 0 1.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
  position: relative;

  @media ${queries.tabletUp} {
    padding-top: 2.5rem;
  }

  @media ${queries.desktopUp} {
    padding-top: 4rem;
  }
`

const Slideshow = () => {

  const { image } = useParams();

  const { loading, data:info } = useQuery(getArt, {
    variables: {uuid:image},
  });

  if (loading){
    return <></>
  }

  let data = undefined;
  if (info?.getArt){

    data = info.getArt;
   
  }

  if (!data){

    return <div>No Art</div>
  }

  return <SlideShowAfter data={data}/>
}


const SlideShowAfter = ({data}) => {
  return (
    <Wrapper
      exit="exit"
      variants={pageAnimation}
      initial="hide"
      animate="show"
      aria-live="polite"
    >
      <Slide data={data}/>
      <SlideFooter  data={data} />
    </Wrapper>
  )
}

export default Slideshow
