import gql from "graphql-tag";

const getGallery = gql`
  query getGallery($uuid: String!) {
    getGallery(uuid: $uuid) {
      _id
      name
      year
      description

      artist {
        image
        name
      }
      images {
        thumbnail
        hero {
          small
          large
        }
        gallery
      }
      purchaseNumber
    }
  }
`;

const getArt = gql`
  query getArt($uuid: String!) {
    getArt(uuid: $uuid) {
      _id
      name
      year
      description

      artist {
        image
        name
      }
      images {
        thumbnail
        hero {
          small
          large
        }
        gallery
      }
      purchaseNumber
    }
  }
`;


const buyArt = gql`
  mutation buyArt($uuid: String!) {
    buyArt(uuid: $uuid) {
      _id
      name
      year
      description

      artist {
        image
        name
      }
      images {
        thumbnail
        hero {
          small
          large
        }
        gallery
      }
      purchaseNumber
    }
  }
`;

export {
  buyArt, 
  getArt,
  getGallery, 
};
