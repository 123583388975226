import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { captionAnimation } from 'Utils/Animations'
import { cardAnimation } from 'Utils/Animations'
import { Caption, Figure } from './GalleryCard.styles'
import { useHistory } from 'react-router-dom'

const GalleryCard = ({
  name,
  images,
  artist,
  _id
}) => {

  const history = useHistory()
  
  return (
    <motion.li variants={cardAnimation}>
      <Link
        onClick={() => {
          history.push("/image/"+_id.split(":")[1])
        }}
      >
        <Figure>
          <img src={images.thumbnail} alt="" />
          <Caption variants={captionAnimation}>
            <h2>{name}</h2>
            <p>{artist.name}</p>
          </Caption>
        </Figure>
      </Link>
    </motion.li>
  )
}

export default GalleryCard