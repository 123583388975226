import { useHistory } from 'react-router-dom'

import { Navigation, StyledHeader, SlideshowButton } from './Header.styles'
import Logo from 'Components/Atoms/Logo'
import { pageAnimation } from 'Utils/Animations'

const Header = () => {

  const history = useHistory()
  const handleClick = () => {
    history.push('/slideshow')
  }

  return (
    <StyledHeader
      exit="exit"
      variants={pageAnimation}
      initial="hide"
      animate="show"
    >
      <Navigation>
        <Logo />
      </Navigation>
    </StyledHeader>
  )
}

export default Header
